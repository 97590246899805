.grid-wrp {
  display: grid;
  grid-template-rows: 58px 1fr;
  overflow: hidden;
  .ant-spin-nested-loading {
    height: 100%;
  }
  .grid-head {
    display: grid;
    /* align-items 和justify-items  */
    place-items: stretch;
    /* align-content 和 justify-content  */
    place-content: stretch;
    height: 58px;
    background-color: #f1e6f6;
    .label {
      box-sizing: border-box;
      padding: 0 0 0 18px;
      font-family: var(--regular-font);
      font-weight: 500;
      font-size: 14px;
      color: var(--darkblue-color);
      line-height: 20px;
      word-break: break-word;
      display: grid;
      align-items: center;
      min-height: 58px;
      .arrow {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: var(--icon-bgd);
        background-size: contain;
        margin-left: 5px;
        cursor: pointer;
        &.descend {
          background-image: url("../../../assets/icon/sort.png");
        }
        &.ascend {
          background-image: url("../../../assets/icon/sort-reverse.png");
        }
      }
    }
    &.user {
      grid-template-areas: "name account goals achieved subs registration status operate";
      grid-template-columns: 16% 18% 8% 12% 13% 15% 10% 8%;
      .label {
        &:nth-child(1) {
          grid-area: name;
        }
        &:nth-child(2) {
          grid-area: account;
        }
        &:nth-child(3) {
          grid-area: goals;
        }
        &:nth-child(4) {
          grid-area: achieved;
        }
        &:nth-child(5) {
          grid-area: subs;
        }
        &:nth-child(6) {
          grid-area: registration;
        }
        &:nth-child(7) {
          grid-area: status;
        }
        &:nth-child(8) {
          grid-area: operate;
        }
      }
    }
    &.statistics {
      grid-template-areas: "name account goals";
      grid-template-columns: 1fr 1fr 180px;
      .label {
        &:nth-child(1) {
          grid-area: name;
        }
        &:nth-child(2) {
          grid-area: account;
        }
        &:nth-child(3) {
          grid-area: goals;
        }
      }
    }
    &.susbscription {
      grid-template-areas: "name plan amount method subs total";
      grid-template-columns: 19% 15% 15% 18% 18% 15%;
      .label {
        &:nth-child(1) {
          grid-area: name;
        }
        &:nth-child(2) {
          grid-area: plan;
        }
        &:nth-child(3) {
          grid-area: amount;
        }
        &:nth-child(4) {
          grid-area: method;
        }
        &:nth-child(5) {
          grid-area: subs;
        }
        &:nth-child(6) {
          grid-area: total;
        }
      }
    }
    &.category {
      grid-template-areas: "icon name number drag operate";
      grid-template-columns: 108px 1fr 1fr 80px 80px;
      .label {
        &:nth-child(1) {
          grid-area: icon;
        }
        &:nth-child(2) {
          grid-area: name;
        }
        &:nth-child(3) {
          grid-area: number;
        }
        &:nth-child(4) {
          grid-area: drag;
        }
        &:nth-child(5) {
          grid-area: operate;
        }
      }
    }
    &.illustrate-check {
      grid-template-areas: "image title category desc operate";
      grid-template-columns: 110px 170px 170px 1fr 110px;
      .label {
        &:nth-child(1) {
          grid-area: image;
        }
        &:nth-child(2) {
          grid-area: title;
        }
        &:nth-child(3) {
          grid-area: category;
        }
        &:nth-child(4) {
          grid-area: desc;
        }
        &:nth-child(5) {
          grid-area: operate;
        }
      }
    }
    &.quote {
      grid-template-areas: "content number date operate";
      grid-template-columns: 1fr 200px 120px 100px;
      .label {
        &:nth-child(1) {
          grid-area: content;
        }
        &:nth-child(2) {
          grid-area: number;
        }
        &:nth-child(3) {
          grid-area: date;
        }
        &:nth-child(4) {
          grid-area: operate;
        }
      }
    }
    &.gold-reminder {
      grid-template-areas: "title operate";
      grid-template-columns: 1fr 110px;
      .label {
        &:nth-child(1) {
          grid-area: title;
        }
        &:nth-child(2) {
          grid-area: operate;
        }
      }
    }
  }
  .grid-body {
    overflow-y: auto;
    .grid-body-loading {
      .ant-spin-container {
        overflow-x: clip;
      }
    }
  }
  .grid-empty {
    padding: 50px 0;
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: 20px;
    color: var(--darkblue-color);
    text-align: center;
  }
  .grid-line {
    display: grid;
    // grid-template-columns: '16% 16% 8% 12% 13% 15% 10% 10%';
    /* align-items 和justify-items  */
    place-items: stretch;
    /* align-content 和 justify-content  */
    place-content: stretch;
    border-bottom: 1px solid #f1e6f6;
    padding: 5px 0;
    .label-th {
      box-sizing: border-box;
      padding: 0 0 0 18px;
      font-family: var(--regular-font);
      font-weight: 500;
      font-size: 14px;
      color: var(--darkblue-color);
      line-height: 18px;
      word-break: break-word;
      display: grid;
      align-items: center;
      min-height: 48px;
    }
    &.user {
      grid-template-areas: "name account goals achieved subs registration status operate";
      grid-template-columns: 16% 18% 8% 12% 13% 15% 10% 8%;
      .label-th {
        &.warn {
          color: var(--warn-color);
        }
        &:nth-child(1) {
          grid-area: name;
        }
        &:nth-child(2) {
          grid-area: account;
        }
        &:nth-child(3) {
          grid-area: goals;
        }
        &:nth-child(4) {
          grid-area: achieved;
        }
        &:nth-child(5) {
          grid-area: subs;
        }
        &:nth-child(6) {
          grid-area: registration;
        }
        &:nth-child(7) {
          grid-area: status;
        }
        &:nth-child(8) {
          grid-area: operate;
          padding-top: 0;
        }
      }
    }
    &.statistics {
      grid-template-areas: "name account goals";
      grid-template-columns: 1fr 1fr 180px;
      .label-th {
        &:nth-child(1) {
          grid-area: name;
        }
        &:nth-child(2) {
          grid-area: account;
        }
        &:nth-child(3) {
          grid-area: goals;
        }
      }
    }
    &.susbscription {
      grid-template-areas: "name plan amount method subs total";
      grid-template-columns: 19% 15% 15% 18% 18% 15%;
      .label-th {
        &:nth-child(1) {
          grid-area: name;
        }
        &:nth-child(2) {
          grid-area: plan;
        }
        &:nth-child(3) {
          grid-area: amount;
        }
        &:nth-child(4) {
          grid-area: method;
        }
        &:nth-child(5) {
          grid-area: subs;
        }
        &:nth-child(6) {
          grid-area: total;
        }
      }
    }
    &.category {
      grid-template-areas: "icon name number drag operate";
      grid-template-columns: 108px 1fr 1fr 80px 80px;
      .label-th {
        &:nth-child(1) {
          grid-area: icon;
        }
        &:nth-child(2) {
          grid-area: name;
        }
        &:nth-child(3) {
          grid-area: number;
        }
        &:nth-child(4) {
          grid-area: drag;
        }
        &:nth-child(5) {
          grid-area: operate;
        }
      }
    }
    &.illustrate-check {
      grid-template-areas: "image title category desc operate";
      grid-template-columns: 110px 170px 170px 1fr 110px;
      .label-th {
        &:nth-child(1) {
          grid-area: image;
        }
        &:nth-child(2) {
          grid-area: title;
        }
        &:nth-child(3) {
          grid-area: category;
        }
        &:nth-child(4) {
          grid-area: desc;
        }
        &:nth-child(5) {
          grid-area: operate;
        }
      }
    }
    &.illustrate-first-screen {
      grid-template-areas: "image title operate";
      grid-template-columns: 110px 1fr 110px;
      .label-th {
        &:nth-child(1) {
          grid-area: image;
        }
        &:nth-child(2) {
          grid-area: title;
        }

        &:nth-child(5) {
          grid-area: operate;
        }
      }
    }
    &.quote {
      grid-template-areas: "content number date operate";
      grid-template-columns: 1fr 200px 120px 100px;
      .label-th {
        &:nth-child(1) {
          grid-area: content;
        }
        &:nth-child(2) {
          grid-area: number;
        }
        &:nth-child(3) {
          grid-area: date;
        }
        &:nth-child(4) {
          grid-area: operate;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &.gold-reminder {
      grid-template-areas: "title operate";
      grid-template-columns: 1fr 110px;
      .label-th {
        &:nth-child(1) {
          grid-area: title;
        }
        &:nth-child(2) {
          grid-area: operate;
        }
      }
    }
  }
}
.dropdown-items {
  width: 160px;
  // height: 107px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px 0;
  .dropdown-item {
    padding: 12px 0 12px 20px;
    box-sizing: border-box;
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: 14px;
    color: var(--darkblue-color);
    line-height: 22px;
    cursor: pointer;
    &:hover {
      background-color: #f7f5f8;
    }
    &.warn {
      color: var(--warn-color);
    }
  }
  &.shorter {
    width: 98px !important;
  }
  &.bigger {
    width: 180px;
  }
}
.dots {
  width: 30px;
  height: 30px;
  background: var(--icon-bgd);
  background-size: contain;
  background-image: url("../../../assets/icon/dots.png");
  transition: all 0.3;
  cursor: pointer;
  &.active {
    background-image: url("../../../assets/icon/dots-active.png");
  }
}
.customize-ico {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #f1e6f6;
  background: var(--icon-bgd);
  background-size: cover;
}
.drag-ico {
  width: 30px;
  height: 30px;
  background: var(--icon-bgd);
  background-size: 24px auto;
  background-image: url("../../../assets/icon/drag.png");
}
